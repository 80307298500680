var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","content-class":"s-css-reset","max-width":_vm.maxWidth,"scrollable":""},model:{value:(_vm.dialogs[_vm.dialogName]),callback:function ($$v) {_vm.$set(_vm.dialogs, _vm.dialogName, $$v)},expression:"dialogs[dialogName]"}},[_c('v-card',{class:_vm.checkText(_vm.confirmBtnText)=='DEL'
                  ? 'error-dialog'
                  : _vm.checkText(_vm.confirmBtnText)=='INFO'
                  ? 'warning-dialog'
                  : ''},[_c('v-row',{staticClass:"my-3 d-flex justify-center align-center"},[_c('v-col',{staticClass:"text-center",style:(_vm.closeButton ? 'margin-left:40px' : '')},[_c('h2',{staticClass:"my-3"},[(_vm.checkText(_vm.confirmBtnText)!='')?_c('v-avatar',{staticClass:"confirm-avatar",attrs:{"size":"60"}},[_c('v-icon',{staticClass:"confirm-color mr-0",attrs:{"size":"50"}},[_vm._v(" mdi-alert-circle-outline ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")],1)]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.closeButton),expression:"closeButton"}],staticClass:"mx-2",attrs:{"icon":"","color":"secondary lighten-1"},on:{"click":function($event){return _vm.toggleDialog(_vm.dialogName)}}},[_c('v-icon',{staticStyle:{"margin":"0px"}},[_vm._v(" mdi-close ")])],1)],1),_c('v-row',[_c('v-divider')],1),_c('v-card-text',[_c('v-row',{staticClass:"pa-4 my-4 d-flex justify-center"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.dialogContent)}}),_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttons),expression:"buttons"}]},[_c('v-row',{staticClass:"my-4 d-flex justify-center"},[_c('v-btn',{staticClass:"mx-4 nocap",attrs:{"text":"","color":"primarytextcolor darken-1"},on:{"click":function($event){return _vm.toggleDialog(_vm.dialogName)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-4 nocap",class:_vm.confirmBtnClass,attrs:{"color":_vm.checkText(_vm.confirmBtnText)=='DEL'
                  ? 'red'
                  : _vm.checkText(_vm.confirmBtnText)!='INFO'
                  ? 'tertiary2'
                  : 'tertiary1',"dark":""},on:{"click":function($event){return _vm.dialogOnConfirmFunctions[_vm.dialogName](_vm.onConfirmValue)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.confirmBtnIcon))]),_vm._v(" "+_vm._s(_vm.confirmBtnText)+" ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }